import { useCallback, useState } from "react";
import FormInput from "../components/FormInput";
import { axiosPost } from "../lib/axiosPost";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: 1,
  });

  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [eye, setEye] = useState(false);
  const [type, setType] = useState("password");

  const handleInput = (e, name) => {
    setFormData((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const handleEye = () => {
    setEye((prev) => !prev);
    setType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      const data = await axiosPost("/api/user/register", formData);

      if (data?.status === 404) {
        setError(data?.error);
        setIsLoading(false);
      }

      if (data?.message === "success") {
        setIsLoading(false);
        setFormData({
          name: "",
          email: "",
          password: "",
          role: 1,
        });
        toast.success("Registration successful.");
        navigate("/login");
      }
    },
    [formData, navigate]
  );

  const handleRoleChange = (role) => {
    setFormData((prev) => ({ ...prev, role }));
  };

  const option = [
    {
      id: 1,
      name: "user",
      role: 1,
    },
    {
      id: 2,
      name: "vendor",
      role: 2,
    },
  ];

  return (
    <div className="flex justify-center items-center min-h-[calc(100vh-12rem)] w-full px-2 md:px-0">
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-lg rounded-md p-4 max-w-[25rem] w-[24rem] h-full flex flex-col justify-center items-center"
      >
        <div className="form-header flex flex-col items-center justify-center">
          <h2 className="text-3xl font-semibold text-[#000] mb-5">
            Create An Account
          </h2>
        </div>
        <FormInput
          label={"Name"}
          type={"text"}
          value={formData.name}
          name={"name"}
          placeholder={"Enter Your Name"}
          onChange={(e) => handleInput(e, "name")}
        />
        <FormInput
          label={"Email"}
          type={"email"}
          value={formData.email}
          name={"email"}
          placeholder={"Enter Your Email"}
          onChange={(e) => handleInput(e, "email")}
        />
        <FormInput
          label={"Password"}
          type={type}
          value={formData.password}
          name={"password"}
          placeholder={"Enter Your Password"}
          onChange={(e) => handleInput(e, "password")}
          onClick={handleEye}
          eye={eye}
        />
        {error && <p className="text-red-500">{error}</p>}
        <div className="my-5 w-full">
          <div className="form-control flex justify-center gap-2">
            {option?.map((data) => (
              <button
                key={data?.id}
                type="button"
                onClick={() => handleRoleChange(data.role)}
                className={`border py-2 px-4 capitalize rounded-md ${
                  formData.role === data.role
                    ? "bg-[#6D0C48] text-white"
                    : "bg-white text-gray-700"
                }`}
              >
                {data?.name}
              </button>
            ))}
          </div>
        </div>
        <button className="bg-[#6D0C48] w-full text-white py-2 mt-2 rounded-full">
          <span className="flex justify-center items-center gap-2">
            {isLoading && (
              <MoonLoader
                color="rgb(255, 255, 255)"
                loading={isLoading}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
            Register
          </span>
        </button>
        <span className="text-gray-400 mt-2">
          Already have an account?{" "}
          <Link className="text-[#6D0C48]" to={"/login"}>
            Login
          </Link>
        </span>
      </form>
    </div>
  );
};

export default SignUp;
