import React, { useEffect, useState } from "react";
import moment from "moment";
import { CiGrid41 } from "react-icons/ci";
import { useSelector } from "react-redux";
import axios from "axios";

const UserOrder = () => {
  const [order, setOrder] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [orderTotal, setOrderTotal] = useState(null);
  const { userAndToken: userData } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URL}/api/user/order/${userData?.user?.id}`
        );
        setOrder(res.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [userData?.user?.id]);

  const fetchOrderDetails = async (orderId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api/user/order_details/${orderId}`
      );
      setSelectedOrder(res.data);
      setShowPopup(true);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleButtonClick = (item) => {
    fetchOrderDetails(item.id);
    setOrderTotal(item);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedOrder(null);
  };

  return (
    <div className="p-5 flex flex-col">
      <span className="flex gap-1 text-2xl items-center font-semibold">
        <CiGrid41 />
        <h2>My Order</h2>
      </span>

      {order?.length > 0 ? (
        <div className="overflow-auto rounded-lg shadow mt-5 order-details-container hidden xl:block">
          <table className="w-full">
            <thead className="bg-gray-50 border-b-2 border-gray-200">
              <tr>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Code
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Date
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Shipping Charge
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Amount
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Delivery Status
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Payment Status
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {order?.slice(0, 10).map((item) => (
                <tr key={item.id} className="bg-gray-50">
                  <td className="p-3 w-24 text-sm text-gray-700 whitespace-nowrap">
                    {item?.code}
                  </td>

                  <td className="p-3 w-24 text-sm text-gray-700 whitespace-nowrap">
                    {moment(item?.created_at).format("DD MMM YY. hh:mm A")}
                  </td>
                  <td className="p-3 w-20 text-sm text-gray-700 whitespace-nowrap">
                    ৳{+item?.shipping_cost}
                  </td>
                  <td className="p-3 w-20 text-sm text-gray-700 whitespace-nowrap">
                    ৳{+item?.grand_total}
                  </td>
                  <td className="p-3 w-20 text-sm text-gray-700 whitespace-nowrap">
                    <span
                      className={`p-1.5 text-xs font-medium uppercase tracking-wider ${
                        item?.delivery_status === "pending" &&
                        "text-yellow-800 bg-yellow-200"
                      } ${
                        item?.delivery_status === "confirmed" &&
                        "text-green-800 bg-green-200"
                      } ${
                        item?.delivery_status === "canceled" &&
                        "text-gray-100 bg-red-200"
                      } rounded-lg bg-opacity-50`}
                    >
                      {item?.delivery_status}
                    </span>
                  </td>
                  <td className="p-3 w-20 text-sm text-gray-700 whitespace-nowrap">
                    <span
                      className={`p-1.5 text-xs font-medium uppercase tracking-wider ${
                        item?.payment_status === "pending" &&
                        "text-yellow-800 bg-yellow-200"
                      } ${
                        item?.payment_status === "paid" &&
                        "text-green-800 bg-green-200"
                      } rounded-lg bg-opacity-50`}
                    >
                      {item?.payment_status}
                    </span>
                  </td>
                  <td className="p-3 w-20 text-sm text-gray-700 whitespace-nowrap">
                    <button
                      className="bg-[#000] text-white px-3 py-1 rounded"
                      onClick={() => handleButtonClick(item)}
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="mt-10 text-center">No Order Found</p>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 xl:hidden">
        {order?.slice(0, 10).map((item) => (
          <div
            key={item.id}
            className="bg-white space-y-3 p-4 rounded-lg shadow"
          >
            <div className="flex items-center space-x-2 text-sm">
              <div>
                <span className="text-blue-500 font-bold hover:underline">
                  {item.code}
                </span>
              </div>
              <div className="text-gray-500">
                {moment(item?.created_at).format("DD MMM YY. hh:mm A")}
              </div>
              <div>
                <span
                  className={`p-1.5 text-xs font-medium uppercase tracking-wider ${
                    item?.delivery_status === "pending" &&
                    "text-yellow-800 bg-yellow-200"
                  } ${
                    item?.delivery_status === "confirmed" &&
                    "text-green-800 bg-green-200"
                  } ${
                    item?.delivery_status === "canceled" &&
                    "text-gray-100 bg-red-200"
                  } rounded-lg bg-opacity-50`}
                >
                  {item?.delivery_status}
                </span>
              </div>
            </div>
            <div className="text-sm font-medium text-black">
              Total Price: ৳{+item?.grand_total}
            </div>
            <button
              className="bg-[#000] text-white px-3 py-1 rounded"
              onClick={() => handleButtonClick(item)}
            >
              View Details
            </button>
          </div>
        ))}
      </div>

      {showPopup && (selectedOrder || orderTotal) && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-3xl mx-4 sm:mx-6 lg:mx-8">
            <button
              className="bg-red-500 text-white px-3 py-1 rounded mb-4"
              onClick={closePopup}
            >
              Close
            </button>
            <h2 className="text-xl font-bold mb-4">Order Details</h2>
            <div className="mb-2">
              <strong>Order #:</strong> {orderTotal?.code}
            </div>
            <div className="mb-2">
              <strong>Order status:</strong> {orderTotal?.delivery_status}
            </div>
            <div className="mb-2">
              <strong>Order date:</strong>{" "}
              {moment(selectedOrder.created_at).format("DD-MM-YYYY hh:mm A")}
            </div>
            <div className="mb-2">
              <strong>Sub Total:</strong> ৳
              {+orderTotal?.grand_total -
                +orderTotal?.shipping_cost -
                orderTotal?.coupon_discount}
            </div>
            <div className="mb-2">
              <strong>Shipping:</strong> ৳{orderTotal?.shipping_cost}
            </div>
            <div className="mb-2">
              <strong>Coupon:</strong> ৳
              {orderTotal?.coupon_discount ? orderTotal?.coupon_discount : 0}
            </div>
            <div className="mb-2">
              <strong>Total:</strong> ৳{orderTotal?.grand_total}
            </div>
            <div className="overflow-auto">
              <table className="w-full mt-4">
                <thead>
                  <tr>
                    <th className="text-left p-2">#</th>
                    <th className="text-left p-2">Product Name</th>
                    <th className="text-left p-2">Delivery Type</th>
                    <th className="text-left p-2">Qty</th>
                    <th className="text-left p-2">Price</th>
                    <th className="text-left p-2">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder && selectedOrder.length > 0 ? (
                    selectedOrder.map((item, index) => (
                      <tr key={index} className="border-b border-gray-200">
                        <td className="p-2">{index + 1}</td>
                        <td className="p-2">{item.product_name}</td>
                        <td className="p-2">{item.delivery_status}</td>
                        <td className="p-2">{item.quantity}</td>
                        <td className="p-2">৳{item.price}</td>
                        <td className="p-2">৳{item.quantity * item.price}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="p-4 text-center">
                        No items found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserOrder;
