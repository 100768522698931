import SectionTitle from "./SectionTitle";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CategoryCard = () => {
  const { items: data } = useSelector((state) => state.category);

  return (
    <div className="wrapper">
      <SectionTitle title={"Categories"} />
      <div className="w-full flex flex-wrap justify-center gap-2">
        {data?.slice(0, 5).map((category) => (
          <Link
            key={category.id}
            to={`/categorypage/${category.id}`}
            className=" w-[16rem]   rounded-lg overflow-hidden"
          >
            <div className="w-full h-60 overflow-hidden">
              <img
                src={`${process.env.REACT_APP_URL}/uploads/category/${category?.icon}`}
                alt=""
                className="w-full h-full object-fill"
              />
            </div>
            <h3 className="text-xl tracking-widest p-2">{category.name}</h3>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CategoryCard;
